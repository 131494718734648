<template>
  <section>
    <!-- <tags-view /> -->
    <transition name="fade" mode="out-in">
      <router-view class="app-main-content" :key="key"></router-view>
    </transition>
    <!-- <FootBar /> -->
  </section>
</template>

<script>
import FootBar from './FootBar'
// import TagsView from './TagsView'

export default {
  name: 'AppMain',

  components: { 
    FootBar, 
    // TagsView
  },

  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>