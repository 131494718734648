<template>
  <div class="sidebar-item">
    <!-- 无二级子菜单 -->
    <el-menu-item :index="item.url" v-if="!item.children || !item.children.length" @click="select">
      <!-- <i v-if="isShowIcon && item.icon" :class="['icon-font', item.icon]"></i> -->
      <!-- <span slot="title">{{showLangChange ? langFormat(item.code) : item.name}}</span> -->
      <span slot="title">{{item.name}}</span>
    </el-menu-item>

    <!-- 存在二级子菜单 -->
    <el-submenu :index="index" v-if="item.children && item.children.length">
      <template slot="title">
        <!-- <i v-if="item.icon" :class="['icon-font', item.icon]"></i> -->
        <!-- <span slot="title">{{showLangChange ? langFormat(item.code) : item.name}}</span> -->
        <span slot="title">{{item.name}}</span>
      </template>
      <SidebarItem
        v-for="(ele, idx) in item.children"
        :key="index+'-'+idx"
        :index="index+'-'+idx"
        :item="ele"
        :showLangChange="showLangChange"
        :isShowIcon="true"
        class="sidebar-item-level2"
      />
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { langFormat } from "@/utils/i18n";
export default {
  name: "SidebarItem",

  props: {
    index: String,
    item: Object,
    showLangChange: Boolean,
    isShowIcon: {
      type: Boolean,
      default:  true
    }
  },

  computed: {
    ...mapGetters("app", ["sidebar"]),

    isCollapse() {
      return !this.sidebar.opened;
    }
  },

  methods: {
    langFormat,

    select({ index }) {
      if (index === this.$route.fullPath) return;
      if (index.startsWith("http://") || index.startsWith("https://")) {
        location.href = index;
      } else {
        this.$router.push({ path: index });
      }
    }
  }
};
</script>
<style lang="scss">
.sidebar-item {
  .el-submenu__title, .el-menu-item {
    padding: 0;
    height: $menu_item_height;
    line-height: $menu_item_height;
    font-family: PingFang SC;
    color: $menu_item_font_color !important;
    .icon-font {
      vertical-align: -16%;
      margin-right: 20px;
      font-size: 24px;
      color: $menu_item_icon_color;
    }
    &:hover, &:focus {
      background-color: $menu_item_bg_acitve !important;
      i, span {
        color: $menu_item_color_acitve;
      }
    }
    &.is-active {
      color: $menu_item_color_acitve !important;
      background-color: $menu_item_bg_acitve !important;
      .icon-font {
        color: $menu_item_color_acitve !important;
      }
      .el-icon-arrow-down {
        color: $menu_item_color_acitve;
      }
    }
  }
  
  .el-icon-arrow-right:before {
    content: unset;
  }

  .el-submenu.is-active > .el-submenu__title{
    background-color: $menu_item_bg_acitve !important;
    color: $menu_item_color_acitve !important;
    .icon-font {
      color: $menu_item_color_acitve;
    }
    .el-icon-arrow-down {
      color: $menu_item_color_acitve;
    }
  }

  // 收起状态下，菜单悬浮框
  .el-menu--popup{
    padding: 0;
  }
}
</style>