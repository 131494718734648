<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="item in list" :key="item.code">
        <span 
          :class="item.url && item.code !== $route.meta.code ? 'active' : ''"
          @click="clickFn(item)"
       >{{showLangChange ? langFormat(item.code) : item.name}}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex'
import { langFormat } from '@/utils/i18n'
export default {
  props: {
    breadcrumbData: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('app', ['showLangChange']),

    list() {
      return this.breadcrumbData.filter(item => item.name)
    },
  },

  methods: {
    langFormat,

    clickFn(item) {
      if(item.url && item.code !== this.$route.meta.code){
        this.$router.push(item.url)
      }
    }
  }
}
</script>

<style lang="scss">
  .app-breadcrumb.el-breadcrumb{
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    margin-left: 10px;
    span{
      color: #97a8be;
      cursor: text;
      &.active{
        color: #303133;
        font-weight: 700;
        cursor: pointer;
        &:hover{
          color: $theme_color;
        }
      }
    }
    // 过渡
    .breadcrumb-enter-active, .breadcrumb-leave-active {
      transition: all .5s;
    }
    .breadcrumb-enter, .breadcrumb-leave-active {
      opacity: 0;
      transform: translateX(20px);
    }
    .breadcrumb-move {
      transition: all .5s;
    }
    .breadcrumb-leave-active {
      position: absolute;
    }
  } 
</style>