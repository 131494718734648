<template>
  <div class="layout-contain" :class="classObj">
    <!-- 左侧菜单栏 -->
    <Sidebar v-if="!$route.query.hiddenSlider" :menus="menus" :currentRoute="currentRoute" />

    <!-- 头部导航栏 -->
    <Navbar v-if="!$route.query.hiddenNavbar" :userInfo="userInfo" :breadcrumbData="breadcrumbData" :style="$route.query.hiddenSlider ? {'left':'0'} : {}" />

    <!-- 主体区域 -->
    <AppMain class="app-main" :style="style" />

    <!-- 返回顶部 -->
    <el-backtop target=".app-main">
      <i class="el-icon-top"></i>
    </el-backtop>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import AppMain from './components/AppMain'
import { USER_INFO } from '@/utils/constant'
import { formatMenu, findBreadcrumbDataList } from '@/utils'

export default {
  name: 'Layout',

  components: {
    Navbar,
    Sidebar,
    AppMain
  },

  data() {
    return{
      userInfo: {},
      userResourceList: [],
      menuList: []
    }
  },

  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },

    classObj() {
      return {
        'layout-contain-hide-sidebar': !this.sidebar.opened
      }
    },

    style(){
      let obj = {}
      if(this.$route.query.hiddenSlider) obj.left = '0'
      if(this.$route.query.hiddenNavbar) obj.top = '0'
      return obj
    },

    menus() {
      // return this.formatMenu(this.userResourceList, 'isInNavigation')
      return this.userResourceList
    },

    breadcrumbData() {
      return findBreadcrumbDataList(this.menuList, this.$route.meta.code)
    },

    currentRoute() {
      return this.breadcrumbData.length ? this.breadcrumbData[this.breadcrumbData.length - 1] : {}
    }
  },

  created(){
    this.userInfo = JSON.parse(window.localStorage.getItem(USER_INFO))
    this.userResourceList = JSON.parse(window.localStorage.getItem(USER_INFO)).userResourceList
    this.menuList = JSON.parse(window.localStorage.getItem(USER_INFO)).userResourceList

    console.log('this.menuList:', this.menuList)
  },

  methods: {
    formatMenu
  }
}
</script>
<style lang="scss">
.layout-contain{
  position: relative;
  height: 100%;
  width: 100%;
  // 左侧菜单区域
  .sidebar-contain{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width 0.3s ease;
    width: $sidebar_width;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $menu_bg;
    box-shadow: 0 0 5px $sidebar_shadow;
  }

  // 顶部菜单区域
  .navbar-contain{
    position: fixed;
    top: 0;
    left: $sidebar_width;
    right: 0;
    font-size: 22px;
    z-index: 99;
    transition: all 0.3s ease;
    height: $menu_logo_height;
    line-height: $menu_logo_height;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e6e6e6;
  }

  // 主体内容区域
  .app-main{
    transition: all 0.3s ease;
    position: absolute;
    top: $menu_logo_height;
    bottom: 0;
    left: $sidebar_width;
    right: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #EFF3FF;
    padding: 10px 10px 0 10px;
    .app-main-content{
      min-height: calc(100vh - 204px);
    }
  }
}

// 左侧菜单收起状态
.layout-contain-hide-sidebar{ 
  .navbar-contain, .app-main{
    left: $sidebar_width_collapse;
  }
  .sidebar-contain{
    width: $sidebar_width_collapse;
    // .el-menu{
    //   width: $sidebar_width_collapse;
    // }
  }
}
</style>