<template>
  <div class="footer">
  	Copyright © 2018 景云 
  	<el-divider direction="vertical"></el-divider> 
  	ICP证: 鄂ICP备17029919号</div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
<style lang="scss" scoped>
.footer{
  width: 100%;
  height: $foot_bar_height;
  line-height: $foot_bar_height;
  color: #666;
  font-size: 12px;
  text-align: center;
}
</style>
