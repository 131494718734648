<template>
  <div class="navbar-contain">
    <!-- 展开 & 折叠左侧sidebar -->
    <!-- <i :class="sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="toggleSideBar"></i> -->

    <!-- 面包屑 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <div class="right-btn-contain">
      <!-- 全屏 & 取消全屏 -->
      <!-- <img class="fullview" :src="require('../../../assets/images/fullpage' + (isFullscreen ? '_exit' : '')  + '.png')" alt @click='fullScreen($event)' /> -->
      
      <!-- 国际化 -->
      <!-- <lang-select v-if="showLangChange"></lang-select> -->

      <!-- <el-tooltip effect="dark" :content="langFormat('theme','navbar')" placement="bottom">
        <theme-picker class="theme-switch right-menu-item"></theme-picker>
      </el-tooltip> -->
      
      <!-- 下拉按钮 -->
      <el-dropdown class="user-box" @command="handleCommand" trigger="hover">
        <span class="el-dropdown-link">
          <!-- <img 
            :src="userInfo.avatar || require('../../../assets/images/user_defaulft.png')" 
            @error="userInfo.avatar = require('../../../assets/images/user_defaulft.png')" 
            class="head-img"
          > -->
          <span>{{userInfo.name || userInfo.nickName}}</span>
        </span>
        <el-dropdown-menu slot="dropdown" class="header-dropdown">
          <!-- <el-dropdown-item command="password">
            <i class="el-icon-setting"/>{{showLangChange ? langFormat('reset','navbar') : '修改密码'}}
          </el-dropdown-item> -->
          <el-dropdown-item command="logout">
            <i class="el-icon-switch-button"/>{{showLangChange ? langFormat('logOut','navbar') : '退出登录'}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from './Breadcrumb'
import LangSelect from './LangSelect'
import ThemePicker from './ThemePicker'
import { langFormat } from '@/utils/i18n'
import { USER_INFO } from '@/utils/constant'

export default {
  components: {
    Breadcrumb,
    LangSelect,
    ThemePicker
  },

  data() {
    return {
      isFullscreen: false,                       // 是否全屏
    }
  },

  props: {
    userInfo: {
      type: Object,
      required: true
    },

    breadcrumbData: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('app', ['sidebar', 'showLangChange'])
  },

  methods: {
    langFormat,

    ...mapActions('app',['toggleSideBar']),

    handleCommand(command) {
      switch (command) {
        case 'password':
          this.$router.push('/auth/reset')
          break;
        case 'logout':
          window.localStorage.removeItem(USER_INFO)
          this.$router.replace('/auth/login')
          break;
        default:
          break;
      }
    },

    // 全屏 & 退出全屏
    fullScreen (el) {
      let isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
      this.isFullscreen = !this.isFullscreen
      // 进入全屏
      if(!isFullscreen) {
        let requestMethod = document.documentElement.requestFullScreen || document.documentElement.webkitRequestFullScreen || document.documentElement.mozRequestFullScreen || document.documentElement.msRequestFullScreen
        if (requestMethod) {
          requestMethod.call(document.documentElement)
        } else if (typeof window.ActiveXObject !== "undefined") {
          let wscript = new ActiveXObject("WScript.Shell");
          if (wscript !== null) {
            wscript.SendKeys("{F11}");
          }
        }
      } 
      // 退出全屏
      else {
        let exitMethod = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.webkitExitFullscreen
        if (exitMethod) {
          exitMethod.call(document)
        } else if (typeof window.ActiveXObject !== "undefined") {
          let wscript = new ActiveXObject("WScript.Shell")
          if (wscript !== null) {
            wscript.SendKeys("{F11}");
          }
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.navbar-contain 
  .el-icon-s-fold, .el-icon-s-unfold
    cursor: pointer
    outline: none
    display: inline-block 
    font-size: 24px
    padding: 0 10px
  .right-btn-contain
    position: absolute 
    right: 20px
    top: 50%
    transform: translateY(-50%)
    height: 100%
    display: flex 
    align-items: center
    .user-box
      &>span 
        display: flex 
        align-items: center 
        height: 100%
        cursor: pointer
        img 
          margin-right: 10px
    .fullview 
      width: 20px
      cursor: pointer
      margin-right: 20px
    .theme-switch
      margin-right: 20px
</style>