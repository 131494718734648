<template>
  <el-dropdown trigger="click" class='lang-chang-btn' @command="handleSetLanguage">
    <img class="lang-img" :src="require('../../../assets/images/lang_' + (language === 'en' ? 'zh' : 'en')  + '.png')" alt />
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="zh" :disabled="language==='zh'">中文</el-dropdown-item>
      <el-dropdown-item command="en" :disabled="language==='en'">English</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { success } from '@/utils/action'

export default {
  computed: {
    ...mapGetters('app', ['language'])
  },

  methods: {
    ...mapActions('app',['setLanguage']),

    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.setLanguage(lang)
      success(lang === 'zh' ? '语言切换成功' : 'switch language success')
    }
  }
}
</script>

<style lang="sass" scoped>
.lang-chang-btn
  width: 22px 
  height: 100%
  display: flex 
  align-items: center
  margin-right: 20px
  img 
    width: 100%
    cursor: pointer
</style>