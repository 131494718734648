<template>
  <div class="sidebar-contain">
    <div class="logo">
      <!-- <img src="../../../../assets/images/auth/logo.png" alt /> -->
      <span>{{showLangChange ? langFormat('projectName', 'project') : ''}}</span>
    </div>

    <el-menu
      :collapse="isCollapse"
      :default-active="active"
      mode="vertical"
      unique-opened
      background-color="#304156"
      text-color="#333"
      active-text-color="#fff"
    >
      <sidebar-item
        :index="idx+''"
        :item="item"
        :showLangChange="showLangChange"
        v-for="(item, idx) in menus"
        :key="idx"
      />
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import { langFormat } from "@/utils/i18n";

export default {
  components: { SidebarItem },
  data() {
    return {
      menuList: []
    };
  },

  computed: {
    ...mapGetters("app", ["sidebar", "showLangChange"]),

    isCollapse() {
      return !this.sidebar.opened;
    },

    // ① $route.query.activeMenu   路由参数存在activeMenu参数，即为校正
    // ② $route.meta.activeMenu    将详情页路由父页面path， 设为activeMenu
    // ③ $route.path
    active() {
      let path = (this.currentRoute && this.currentRoute.activeMenu) || this.$route.path;
      return path
    }
  },

  props: {
    menus: {
      type: Array,
      required: true
    },

    currentRoute: {
      type: Object,
      required: true
    }
  },

  methods: {
    langFormat
  }
};
</script>
<style lang="scss" scoped>
.sidebar-contain {
  .logo {
    position: relative;
    display: flex;
    align-items: center;
    height: $menu_logo_height;
    line-height: $menu_logo_height;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    color: #fff;
    background: $menu_logo_bg;
    img {
      margin-left: 22px;
      width: 22px;
      height: 22px;
    }
    span {
      margin-left: 20px;
    }
  }

  .el-menu {
    position: absolute;
    top: $menu_logo_height;
    bottom: 0;
    left: 0;
    border-radius: 0;
    margin-right: -10px;
    border-right: none;
    overflow-y: scroll;
    transition: all 0.3s ease;
    &:not(.el-menu--collapse) {
      transition: all 0.3s ease;
      width: $sidebar_width;
      min-height: 400px;
      margin-right: -10px;
    }
  }
}
</style>